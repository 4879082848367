import React, { type ReactNode } from 'react'

import { scopedTranslation } from '@utils/I18n'
import Button from '@atoms/Button'
import Icon from '@components/utils/Icon'
import useSupporterFilteringStore from './stores/useSupporterFilteringStore'
import { ExportSupportersButton } from '../export-supporters/ExportSupportersButton'

const tActiveFilters = scopedTranslation('components.active_filters_bar')
const tAttributes = scopedTranslation('attributes.supporter')
const tPredicates = scopedTranslation('shared.predicates')

function ChipRemoveButton({ onClick }) {
  return (
    <button onClick={onClick}>
      <Icon type="x-close" width={10} height={10} />
    </button>
  )
}

function Chip({ children }: { children: ReactNode }) {
  return (
    <div className="tw-inline-flex tw-items-center tw-py-3 tw-px-4 tw-rounded-lg tw-bg-primary-50 tw-text-sm tw-text-primary-700 tw-font-medium tw-gap-2">
      {children}
    </div>
  )
}

function valuePresenter(values) {
  if (values === 'true' || values === 'false') {
    return ''
  }
  return Array.isArray(values) ? values.join(', ') : values
}

type ActiveFiltersBarProps = {
  groupId: string
  pdfExportEnabled: boolean
}

function ActiveFiltersBar({ groupId, pdfExportEnabled }: ActiveFiltersBarProps) {
  const removeFilters = useSupporterFilteringStore((state) => state.removeFilters)
  const clearFilters = useSupporterFilteringStore((state) => () => {
    state.replaceFilters([])
  })
  const activeFilters = useSupporterFilteringStore((state) => state.filters)
  const nameSearch = useSupporterFilteringStore((state) => state.supporterName)
  const sortBy = useSupporterFilteringStore((state) => state.currentSort)

  if (!activeFilters || activeFilters.length === 0) return null

  return (
    <div className="tw-flex tw-justify-between tw-py-4 tw-px-6 tw-border-t tw-border-gray-300">
      <div className="tw-flex tw-h-fit tw-flex-auto tw-justify-start tw-flex-wrap tw-gap-3">
        <h1 className="tw-sr-only">{tActiveFilters('title')}</h1>
        {activeFilters.map((filter, index) => {
          const { property, predicate, values } = filter
          const value = valuePresenter(values)
          return (
            <Chip key={`${property}.${predicate}-${index}`}>
              {tAttributes(property)} {tPredicates(predicate).toLowerCase()} {value}
              <ChipRemoveButton
                onClick={() => {
                  removeFilters([filter])
                }}
              />
            </Chip>
          )
        })}
        <div></div>
      </div>
      <div className="tw-flex tw-flex-auto tw-w-min tw-justify-end tw-flex-wrap tw-gap-2">
        {pdfExportEnabled && (
          <ExportSupportersButton
            asChild
            exportRequestParams={{
              exportFormat: 'pdf',
              groupId,
              filters: activeFilters,
              nameSearch,
              sortBy,
            }}
          >
            <Button rank="link" leadingIcon="download-cloud" ariaLabel={tActiveFilters('aria_labels.export_pdf')}>
              {tActiveFilters('buttons.export_pdf')}
            </Button>
          </ExportSupportersButton>
        )}
        <Button
          leadingIcon="refresh"
          onClick={() => {
            clearFilters()
          }}
        >
          {tActiveFilters('buttons.clear_filters')}
        </Button>
      </div>
    </div>
  )
}

export default ActiveFiltersBar
