import React, { useState } from 'react'
import type { Group, GroupSupporterEdge, Supporter, User, GroupCriteriaRule } from '@frontend/graphql/types.generated'
import { clsx } from 'clsx'

import { Button } from '@atoms'
import { Sheet, SheetTrigger, SheetContent, SheetClose } from '@atoms/Sheet'
import Pagination from '@molecules/Pagination'
import LoadingWrapper from '@molecules/LoadingWrapper'
import EmptyState from '@molecules/EmptyState'
import { scopedTranslation } from '@utils/I18n'

import { SupporterCard } from '../features/supporters/GroupShowSupporterCard'
import { ContactStats, SupportersStats } from '../features/supporters/GroupShowStats'
import GroupCard from '../features/supporters/GroupShowGroupCard'
import AddSupporter from '../features/add-supporter/AddSupporter'

import FilterDialog from '../features/filter-supporters/FilterDialog'

import SortSupporters from '../features/sort-supporters/SortSupporters'

import SupporterSearchField from '../features/search-supporters/SupporterSearchField'

import ActiveFiltersBar from '../features/filter-supporters/ActiveFiltersBar'
import SearchSummaryBar from '../features/search-supporters/SearchSummaryBar'

import { SupporterFilteringProvider } from '../features/filter-supporters/stores/useSupporterFilteringStore'
import { type BaseActiveFilter } from '../features/filter-supporters/stores/filterStoreSlice'

const t = scopedTranslation('shared')
const tEmptyState = scopedTranslation('group_show.empty_state')

type SupporterEdgeType = Pick<GroupSupporterEdge, 'membership'> & {
  node: Pick<
    Supporter,
    'id' | 'firstName' | 'lastName' | 'engagementLevel' | 'createdAt' | 'externalCreatedAt' | 'lastContactedAt'
  >
}

export type GroupsShowType = {
  globalMailerSender: 'string'
  group: Pick<
    Group,
    | 'id'
    | 'name'
    | 'description'
    | 'publicPublish'
    | 'publicDescription'
    | 'streetAddress'
    | 'city'
    | 'postcode'
    | 'state'
    | 'notifyAllAdmins'
    | 'publicUrl'
    | 'status'
    | 'mailerSender'
  > & {
    supporters: Pick<Group['supporters'], 'totalCount'>
    pointPeople: {
      nodes: Array<Pick<User, 'email'>>
    }
    captains: {
      nodes: Array<Pick<User, 'firstName' | 'lastName'>>
    }
    emailThreads: {
      totalCount: number
    }
    smsThreads: {
      totalCount: number
    }
    events: {
      totalCount: number
    }
    criteria: {
      combinator: Group['criteria']['combinator']
      rules: {
        nodes: Array<Pick<GroupCriteriaRule, 'id' | 'predicate' | 'property' | 'values'>>
      }
    }
    emailContacts: Pick<Group['contacts'], 'totalCount'>
    smsContacts: Pick<Group['contacts'], 'totalCount'>
    phoneContacts: Pick<Group['contacts'], 'totalCount'>
  }
  supporters: Pick<Group['supporters'], 'totalCount' | 'totalPages'> & {
    edges: SupporterEdgeType[]
  }
  newSupportersCount: number
  activeSearch: {
    filters: BaseActiveFilter[]
    page?: string
    sortBy?: string
    supporterName?: string
  }
}

export type SharedInertiaProps = {
  permissions: {
    emailInboxEnabled?: boolean
    canEditGroup?: boolean
    canDestroyGroup?: boolean
    canSyncGroup?: boolean
    canAddSupporters?: boolean
    electoralFiltersEnabled?: boolean
    pdfExportEnabled?: boolean
    canEditMailerSender?: boolean
  }
}

export type RouteProps = {
  routes: {
    emailInboxRoute: string
    smsInboxRoute: string
    analyticsRoute: string
    callListsRoute: string
    advancedFiltersRoute: string
    optOutsRoute: string
    groupEventsRoute: string
  }
}

function GroupsShow({
  globalMailerSender,
  group,
  supporters,
  newSupportersCount,
  permissions,
  routes,
  activeSearch,
}: GroupsShowType & SharedInertiaProps & RouteProps) {
  const [searchLoading, setSearchLoading] = React.useState(false)
  const [sheetPortalContainer, setSheetPortalContainer] = React.useState(null)
  const [isSheetOpen, setSheetOpen] = useState(false)
  const partialReloadKeys = ['supporters', 'activeSearch']
  const { totalCount } = group.supporters
  const matchingSupportersCount = supporters.totalCount

  return (
    <SupporterFilteringProvider
      initProps={{ ...activeSearch, setLoading: setSearchLoading, currentSort: activeSearch.sortBy }}
      permissions={permissions}
    >
      <div className="tw-flex tw-flex-col tw-gap-6 tw-px-4">
        {permissions.canAddSupporters && (
          <div className="tw-pt-6 tw-flex tw-justify-end">
            <Sheet open={isSheetOpen} onOpenChange={setSheetOpen}>
              <SheetTrigger asChild={true}>
                <Button dataCompId={'group-detail-page_add-new-supporter'}>{t('navigation.add_new_supporter')}</Button>
              </SheetTrigger>
              <SheetContent portalContainer={sheetPortalContainer}>
                <AddSupporter group={group} criteria={group.criteria} />
                <div className="tw-flex tw-w-full tw-justify-center tw-pb-8">
                  <SheetClose asChild={true}>
                    <Button rank="link" color="danger">
                      {t('buttons.close')}
                    </Button>
                  </SheetClose>
                </div>
              </SheetContent>
            </Sheet>
            {/* @ts-expect-error Type 'Dispatch<SetStateAction<null>>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
            <div ref={setSheetPortalContainer}></div>
          </div>
        )}
        <GroupCard globalMailerSender={globalMailerSender} group={group} permissions={permissions} routes={routes} />
        <div className="tw-flex tw-gap-4">
          <SupportersStats total={totalCount} last30Days={newSupportersCount} />
          <ContactStats
            emails={group.emailContacts.totalCount}
            sms={group.smsContacts.totalCount}
            calls={group.phoneContacts.totalCount}
          />
        </div>
        <div className="tw-w-full tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden tw-shadow-sm">
          <div>
            <div className="tw-flex tw-items-center tw-gap-4 tw-p-6">
              <div className="tw-flex tw-flex-1 tw-max-w-xs">
                <SupporterSearchField />
              </div>
              <div>
                <SortSupporters />
              </div>
              <div>
                <FilterDialog />
              </div>
            </div>
            <div>
              <ActiveFiltersBar
                groupId={group.id}
                pdfExportEnabled={Boolean(permissions.pdfExportEnabled) && supporters.totalCount !== 0}
              />
            </div>
          </div>

          <LoadingWrapper loading={searchLoading}>
            <SearchSummaryBar searchLoading={searchLoading} matchingCount={matchingSupportersCount} />

            <div className={clsx('first:[&>*]:tw-border-t [&>*]:tw-border-t-2  [&>*]:tw-border-gray-300')}>
              {supporters.totalCount === 0 ? (
                <EmptyState headline={tEmptyState('headline')} subtext={tEmptyState('subtext')} iconType={'users-01'}>
                  <Button
                    size="xl"
                    rank="primary"
                    trailingIcon="plus-circle"
                    onClick={() => {
                      setSheetOpen(true)
                    }}
                    ariaLabel={tEmptyState('button')}
                  >
                    {tEmptyState('button')}
                  </Button>
                </EmptyState>
              ) : (
                supporters.edges.map((supporterEdge, index) => (
                  <SupporterCard
                    {...supporterEdge}
                    groupId={group.id}
                    key={supporterEdge.node.id}
                    dataCompId={`supporter-card-${index}`}
                  />
                ))
              )}
            </div>
          </LoadingWrapper>
        </div>

        <div className="tw-pb-6">
          {matchingSupportersCount > 0 && (
            <Pagination totalPages={supporters.totalPages ?? 1} only={partialReloadKeys} />
          )}
        </div>
      </div>
    </SupporterFilteringProvider>
  )
}

export default GroupsShow
