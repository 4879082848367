import { scopedTranslation } from '@utils/I18n'
import { type SelectOption } from '../FilterDialog'
import { type StoreState as FilteringParamsStoreState } from './useSupporterFilteringStore'
import { type StateCreator } from 'zustand'
import useSupportersSearch from '../../shared/hooks/useSupportersSearch'
import { type SharedInertiaProps } from '../../../pages/GroupShow'

const tAttributes = scopedTranslation('attributes')
const tShared = scopedTranslation('shared')

export type AvailableFilterPredicate = {
  label: string
  value: string
  array: boolean
  defaultValue?: boolean
}

export type AvailableFilter = {
  value: string
  label: string
  type: string
  predicates: AvailableFilterPredicate[]
}

export type FilterValue = string[] | string | boolean | null

export type BaseActiveFilter = {
  property: string
  predicate: string
  values: FilterValue
}

export type ActiveFilterWithSelectOption =
  | BaseActiveFilter
  | {
      property: BaseActiveFilter['property']
      predicate: BaseActiveFilter['predicate']
      values: SelectOption[]
    }

export type ActiveFilterWithValueType<T> = {
  property: BaseActiveFilter['property']
  predicate: BaseActiveFilter['predicate']
  values: T
}

export type AvailableFilters = Record<string, AvailableFilter>

export const availableFilters: AvailableFilters = {
  first_name: {
    value: 'first_name',
    label: tAttributes('supporter.first_name'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.i_cont'),
        value: 'i_cont',
        array: false,
      },
    ],
  },
  last_name: {
    value: 'last_name',
    label: tAttributes('supporter.last_name'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.i_cont'),
        value: 'i_cont',
        array: false,
      },
    ],
  },
  email: {
    value: 'email',
    label: tAttributes('supporter.email'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.present'),
        value: 'present',
        defaultValue: true,
        array: false,
      },
      {
        label: tShared('predicates.blank'),
        value: 'blank',
        defaultValue: true,
        array: false,
      },
    ],
  },
  primary_mobile: {
    value: 'primary_mobile',
    label: tAttributes('supporter.mobile'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.present'),
        value: 'present',
        defaultValue: true,
        array: false,
      },
      {
        label: tShared('predicates.blank'),
        value: 'blank',
        defaultValue: true,
        array: false,
      },
    ],
  },
  primary_phone: {
    value: 'primary_phone',
    label: tAttributes('supporter.phone'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.present'),
        value: 'present',
        defaultValue: true,
        array: false,
      },
      {
        label: tShared('predicates.blank'),
        value: 'blank',
        defaultValue: true,
        array: false,
      },
    ],
  },
  city: {
    value: 'city',
    label: tAttributes('supporter.city'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  state: {
    value: 'state',
    label: tAttributes('supporter.state'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  zip: {
    value: 'zip',
    label: tAttributes('supporter.zip'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.start'),
        value: 'start',
        array: false,
      },
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  tags: {
    value: 'tags',
    label: tShared('resource_names.tags'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.has_any'),
        value: 'has_any',
        array: true,
      },
      {
        label: tShared('predicates.has_all'),
        value: 'has_all',
        array: true,
      },
      {
        label: tShared('predicates.not_any'),
        value: 'not_any',
        array: true,
      },
    ],
  },
  supranational_district: {
    value: 'supranational_district',
    label: tAttributes('supporter.supranational_district'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  federal_district: {
    value: 'federal_district',
    label: tAttributes('supporter.federal_district'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  county_district: {
    value: 'county_district',
    label: tAttributes('supporter.county_district'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  state_lower_district: {
    value: 'state_lower_district',
    label: tAttributes('supporter.state_lower_district'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  school_district: {
    value: 'school_district',
    label: tAttributes('supporter.school_district'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
  precinct: {
    value: 'precinct',
    label: tAttributes('supporter.precinct'),
    type: 'string',
    predicates: [
      {
        label: tShared('predicates.in'),
        value: 'in',
        array: true,
      },
      {
        label: tShared('predicates.not_in'),
        value: 'not_in',
        array: true,
      },
    ],
  },
}

// TODO: Create a predicates hash
// Reference it in the availableFilters hash
// And export this const as all predicates where array: true
const allArrayPredicates = Object.entries(availableFilters).reduce((acc, [_, filter]) => {
  return [...acc, ...filter.predicates.filter((predicate) => predicate.array).map((predicate) => predicate.value)]
}, [])

const arrayPredicates = [...new Set(allArrayPredicates)]

export function usesArrayPredicate(filter: BaseActiveFilter): filter is ActiveFilterWithValueType<string[]>
export function usesArrayPredicate(
  filter: ActiveFilterWithSelectOption
): filter is ActiveFilterWithValueType<SelectOption[]>

export function usesArrayPredicate(filter: BaseActiveFilter) {
  return arrayPredicates.includes(filter.predicate)
}

function availableFiltersByPermissions(permissions: SharedInertiaProps['permissions']) {
  if (!permissions.electoralFiltersEnabled) {
    const electoralKeys = [
      'supranational_district',
      'federal_district',
      'county_district',
      'state_lower_district',
      'school_district',
      'precinct',
    ]
    return Object.keys(availableFilters).reduce((newFilters, filterKey) => {
      if (!electoralKeys.includes(filterKey)) {
        newFilters[filterKey] = availableFilters[filterKey]
      }

      return newFilters
    }, {})
  }

  return availableFilters
}

export type StoreState = {
  filters: BaseActiveFilter[]
  availableFilters: AvailableFilters
}

export type StoreActions = {
  removeFilters: (filtersToRemove: BaseActiveFilter[]) => void
  addFilters: (filtersToAdd: BaseActiveFilter[]) => void
  replaceFilters: (newFilters: BaseActiveFilter[]) => void
}

type SliceCreator = StateCreator<FilteringParamsStoreState, [], [], StoreState & StoreActions>
type StateCreatorWithPermissions = (
  ...args: [...[permissions: SharedInertiaProps['permissions']], ...Parameters<SliceCreator>]
) => ReturnType<SliceCreator>

export const createFilterSlice: StateCreatorWithPermissions = (permissions, set, get, _store) => {
  return {
    availableFilters: availableFiltersByPermissions(permissions),
    filters: [],
    removeFilters(filtersToRemove: BaseActiveFilter[]) {
      const newFilters = get().filters.filter((filter) => !filtersToRemove.includes(filter))
      useSupportersSearch({
        activeFilters: newFilters,
        currentSort: get().currentSort,
        supporterName: get().supporterName,
        setLoading: get().setLoading,
      })
    },
    addFilters(filtersToAdd: BaseActiveFilter[]) {
      const newFilters = [...get().filters, ...filtersToAdd]
      useSupportersSearch({
        activeFilters: newFilters,
        currentSort: get().currentSort,
        supporterName: get().supporterName,
        setLoading: get().setLoading,
      })
    },
    replaceFilters(newFilters: BaseActiveFilter[]) {
      useSupportersSearch({
        activeFilters: newFilters,
        currentSort: get().currentSort,
        supporterName: get().supporterName,
        setLoading: get().setLoading,
      })
    },
  }
}
