import React, { useState } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { Button, LoadingSpinner } from '@atoms'
import * as ConfirmationModal from '@molecules/ConfirmationModal'
import { scopedTranslation } from '@utils/I18n'
import useMutation from '@hooks/useGQLMutation'
import { exportGroupSupporters } from './mutations'
import type { ExportGroupSupportersDocument } from './mutations.generated'
import type { BaseActiveFilter } from '../filter-supporters/stores/filterStoreSlice'

const tExportDialog = scopedTranslation('components.supporter_export_dialog')

type ExportState = 'idle' | 'loading' | 'succeeded' | 'failed'
type ExportType = 'group' | 'filter'
type ExportModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  exportState: ExportState
  exportType: ExportType
}

type ExportSupportersRequestParams = {
  // exportType: 'group' | 'filter'
  exportFormat: 'pdf' // 'pdf' | 'csv'
  groupId: string
  sortBy?: string
  nameSearch?: string
  filters?: BaseActiveFilter[]
}

type SearchFilterType = Record<string, BaseActiveFilter['values']>

type ExportSupportersButtonProps = {
  asChild?: boolean
  children: React.ReactNode
  exportRequestParams: ExportSupportersRequestParams
}

const buildContent = (exportState: ExportState, exportType: ExportType) => {
  if (exportState === 'loading')
    return (
      <div className="tw-mb-1">
        <LoadingSpinner />
      </div>
    )

  if (exportState === 'failed') return tExportDialog('supporter_export_failed')

  return exportType === 'filter' ? tExportDialog('filter_export_successful') : tExportDialog('group_export_successful')
}

const ExportSupportersDialog = ({ open, setOpen, exportState, exportType }: ExportModalProps) => {
  return (
    <ConfirmationModal.Root open={open} setOpen={setOpen}>
      <ConfirmationModal.Header>{tExportDialog('title')}</ConfirmationModal.Header>
      <div className="tw-pb-2 tw-text-center tw-flex tw-justify-center">{buildContent(exportState, exportType)}</div>
    </ConfirmationModal.Root>
  )
}

const toCamelCase = (str: string) => {
  return str.replace(/[-_\s]+(.)/g, (_match, char) => char.toUpperCase())
}

// Convert to SupporterSearchFiltersInputType format
// e.g. [{ fullNameICont: params[:supporter_name] }]
const convertFiltersToQueryInputs = (filters: BaseActiveFilter[] | undefined): SearchFilterType[] => {
  return (filters ?? []).map((filter) => {
    const { property, predicate, values } = filter
    return {
      [toCamelCase(`${property}_${predicate}`)]: values,
    }
  })
}

const buildFilters = (filters: BaseActiveFilter[] | undefined, nameSearch: string | undefined): SearchFilterType[] => {
  const searchFilters = convertFiltersToQueryInputs(filters)
  if (!nameSearch) return searchFilters
  return searchFilters.concat([{ fullNameICont: nameSearch }])
}

// REFERENCES:
// - https://www.radix-ui.com/primitives/docs/utilities/slot
// - https://www.jacobparis.com/content/react-as-child
// - https://www.radix-ui.com/primitives/docs/guides/composition
// - https://github.com/radix-ui/primitives/blob/main/packages/react/slot/src/Slot.tsx
export const ExportSupportersButton = React.forwardRef<HTMLButtonElement, ExportSupportersButtonProps>(
  ({ asChild, exportRequestParams, ...props }, forwardedRef) => {
    const { groupId, exportFormat, sortBy, nameSearch, filters: baseFilters } = exportRequestParams
    const [exportDialogOpen, setExportDialogOpen] = useState(false)
    const [exportState, setExportState] = useState<ExportState>('idle')

    const Comp = asChild ? Slot : Button
    const filters = buildFilters(baseFilters, nameSearch)
    const exportType = filters.length > 0 ? 'filter' : 'group'

    const { mutate } = useMutation<typeof ExportGroupSupportersDocument>(exportGroupSupporters)
    const exportSupportersRequest = () => {
      setExportState('loading')
      mutate(
        {
          exportFormat,
          groupId,
          sortBy,
          filters,
        },
        {
          onSuccess: (response) => {
            if (response.exportGroupSupporters?.success) {
              setExportState('succeeded')
            } else {
              setExportState('failed')
            }
          },
          onError: () => {
            setExportState('failed')
          },
          onSettled: () => {
            setExportDialogOpen(true)
          },
        }
      )
    }

    return (
      <>
        <Comp {...props} ref={forwardedRef} onClick={exportSupportersRequest} />

        <ExportSupportersDialog
          exportType={exportType}
          exportState={exportState}
          open={exportDialogOpen}
          setOpen={setExportDialogOpen}
        />
      </>
    )
  }
)
ExportSupportersButton.displayName = 'ExportSupportersButton'
