import React from 'react'
import { Button, Dialog } from '@atoms'

function Header({ children }) {
  return (
    <Dialog.Header>
      <div className="tw-flex tw-justify-center tw-text-center">
        <h1 className="tw-text-gray-900 tw-font-bold tw-text-3xl">{children}</h1>
      </div>
    </Dialog.Header>
  )
}

function Root({ children, open, setOpen }) {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content className="tw-p-16">
        {children}
        <div className="tw-flex tw-justify-center tw-items-center">
          <Dialog.Close asChild>
            <Button color="success" leadingIcon="check" size="xl">
              <span className="tw-font-bold">Okay</span>
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export { Root, Header }
